import { Script, ScriptStrategy } from 'gatsby';
import { isProd } from 'gatsby-theme-agria/src/client';
import React from 'react';

export function ScriptsBefore() {
  const gaCode = process.env.GATSBY_GA_CODE ?? '';
  return (
    <>
      {/* // OneTrust Cookies Consent Notice */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script={`6838ac24-c4a1-49da-814b-86be451dcdeb${
        !isProd ? '-test' : ''
      }`}
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
    />
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gaCode}');
      `}
      </Script>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gaCode}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="gtm-frame"
        />
      </noscript>
    </>
  );
}
